import React from 'react';

import PageLayout from '../../../components/PageLayout';

import Home from './Home';

async function action({ query_unused, store_unused }) {
  return {
    title: 'Flux Tech | Software Company Focusing on Web Technologies',
    description:
      'We are a web development company at heart. Our sharp focus on building thoughtful web products allows us to offer our clients a wide array of possible solutions.',
    chunks: ['home'],
    component: (
      <PageLayout>
        <Home key="home" />
      </PageLayout>
    ),
  };
}

export default action;
