import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';

import { faLaptopCode, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import uniqueId from 'lodash/uniqueId';

import {
  Col,
  Grid,
  Row,
} from '../../../incubation/components/ResponsiveGridLayout';

import Link from '../../../components/Link';

import shadowerThumbnail from '../../../../../public/images/uncompressed/music-studio@450x300.jpg';
import marcMarmelThumbnail from '../../../../../public/images/uncompressed/marc-marmel@450x300.jpg';
import saraThumbnail from '../../../../../public/images/uncompressed/sara-thumbnail.jpg';

import saraLogo from '../../../../../public/images/uncompressed/sara-logo.png';

import s from './Home.scss';

const testimonials = [
  {
    image: {
      alt: 'Matt Luongo',
      src: '/images/uncompressed/matt-luongo-poster.jpeg',
      href: 'https://www.linkedin.com/in/mattluongo/',
    },
    paragraph: {
      content:
        "Jonathan is a flexible, thoughtful, scrappy developer. He's quick to learn and adapt — both new technologies as well as a changing startup — and picks up biz requirements and product ideas naturally. He's also a source of energy and passion across the team, works great cross-functionally, and is autonomous without being a lone wolf. You'd be lucky to have him on your team.",
      name: 'Matt Luongo | CEO, Thesis*',
    },
  },
  {
    image: {
      alt: 'Jason Pacini',
      src: '/images/uncompressed/jason-poster.jpg',
    },
    paragraph: {
      content:
        'I have been working with Flux Tech for a couple months now to build a web-based application and it has been a very smooth experience. Jonathan and Dan are very knowledgeable and professional.',
      name: 'Jason Pacini | Founder, Shadower',
    },
  },
  {
    image: {
      alt: 'Tom Edwards',
      src: '/images/uncompressed/tom-edwards.jpg',
    },
    paragraph: {
      content:
        'Jonathan is an intelligent and motivated individual. He is more than capable of designing a website and much more. As part of his former responsibilities, Jonathan was tasked to design two websites and integrate ecommerce. I would highly recommend him.',
      name: 'Tom Edwards | Department Head - Mechanical Engineering, SARA Inc',
    },
  },
  {
    image: {
      alt: 'Justin Kovach',
      src: '/images/uncompressed/justin-kovach-square.jpg',
    },
    paragraph: {
      content:
        'Our experience with Flux Tech was excellent. Incredible responsiveness. Whenever an issue arose, it was resolved amazingly quickly. Pleasant, professional and very easy to work with.',
      name: 'Justin Kovach',
    },
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonialNumber: 1,
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={s.backgroundImage} />
        <section className={s.headSection}>
          <div>
            <h1>Flux Tech</h1>
            <h2>PASSION FOR INNOVATION</h2>
          </div>
        </section>
        <section className={s.introSection}>
          <Grid>
            <Row>
              <Col lg={12}>
                <h2 className="font-400">
                  We are a <strong>software company</strong> that can help you
                  achieve your goals whether you are a{' '}
                  <strong>startup, enterprise, or non-profit.</strong>
                </h2>
              </Col>
            </Row>
            {/*
            <Row>
              <Col lg={12}>
                <h3>Learn How We Can Help You</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <div className={s.helpContainer}>
                  <h2>Startup</h2>
                  <p>
                    Worrying about the details of software development is taking
                    your time away from making important business decisions.
                  </p>
                  <Link to="/startup">Learn More</Link>
                </div>
              </Col>
              <Col lg={4}>
                <div className={s.helpContainer}>
                  <h2>Enterprise</h2>
                  <p>
                    Be the CTO you were meant to be. Hire a company that
                    understands the technical decisions that you have to make on
                    a daily basis.
                  </p>
                  <Link to="/enterprise">Learn More</Link>
                </div>
              </Col>
              <Col lg={4}>
                <div className={s.helpContainer}>
                  <h2>Non Profit</h2>
                  <p>
                    As a non profit it's much more important that you spend your
                    time helping people and not building a dev team.
                  </p>
                  <Link to="/non-profit">Learn More</Link>
                </div>
              </Col>
            </Row>
            */}
          </Grid>
        </section>

        <section className={s.featuredProjects}>
          <Grid>
            <Row>
              <Col lg={12}>
                <h2>Clients We've Helped</h2>
              </Col>

              <Col md={4}>
                <div className={s.featuredProject}>
                  <div>
                    <svg
                      className={s.shadowerLogo}
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="1.414"
                      viewBox="0 0 566 645"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        // eslint-disable-next-line
                        d="M0.026,214.839c0.001,-118.531 126.797,-214.762 282.974,-214.762c155.629,0 281.915,95.35 282.967,213.276l0.007,0l0,1.486l0,0l0,213.276l-0.007,-0.007c0.005,0.498 0.007,0.995 0.007,1.493c0,118.53 -126.796,214.762 -282.974,214.762c-156.282,0 -282.973,-96.151 -282.974,-214.762c0,0 0,0 0,0c0,0 0,-214.762 0,-214.762l0,0Zm495.169,71.189l-0.704,-71.013c0,-88.647 -94.688,-160.686 -211.491,-160.686c-116.725,0 -211.491,71.922 -211.491,160.51c0,88.647 94.687,160.509 211.491,160.51l0,-53.129c78.141,0 141.487,48.076 141.487,107.381c0,59.265 -63.398,107.381 -141.487,107.381c-78.14,0 -141.486,-48.075 -141.487,-107.381l70.501,0l-70.501,0c0,-13.839 0,-28.731 0,-28.731l-0.007,-0.004c-26.607,-11.682 -50.476,-26.557 -70.695,-43.937l-0.006,-0.004c-0.259,5.295 0.066,68.079 0.073,74.674c0.001,88.649 95.32,158.512 212.122,158.512c116.725,0 211.491,-71.922 211.491,-160.51c0,-88.646 -94.687,-160.509 -211.491,-160.51l0,53.129c-78.141,0 -141.487,-48.076 -141.487,-107.381c0,-59.265 63.398,-107.381 141.487,-107.381c77.487,0 140.426,47.275 141.474,105.895l0.013,0l0,1.486l0,0l0,27.245l9.114,5.664c19.945,9.534 38.211,20.905 54.386,33.801l7.208,4.479Zm-212.195,89.699l0,53.874c-24.543,0 -48.355,-2.371 -71.061,-6.829c0.045,2.643 0.075,5.071 0.075,6.829c0.001,29.755 31.782,53.875 70.986,53.875c39.178,0 70.986,-24.141 70.986,-53.875c0,-29.754 -31.781,-53.874 -70.986,-53.874Zm70.992,-160.888l-0.006,0c0,-29.754 -31.781,-53.874 -70.986,-53.874c-39.178,0 -70.986,24.14 -70.986,53.874c0,29.754 31.781,53.874 70.986,53.875l0,-53.875c24.552,0 48.374,2.373 71.088,6.834c-0.047,-2.511 -0.083,-4.911 -0.096,-6.834Z"
                        fill="rgba(50,50,50,1)"
                      />
                    </svg>
                  </div>
                  <img alt="shadower background" src={shadowerThumbnail} />
                  <h3>Shadower</h3>
                  <p>Skill shadowing in real life</p>
                  <Link to="/portfolio/shadower">View Project</Link>
                </div>
              </Col>
              <Col md={4}>
                <div className={s.featuredProject}>
                  <div className={s.qsellaBrandColor}>
                    <img
                      alt="marc marmel"
                      src="https://d33wubrfki0l68.cloudfront.net/49e086495ce21e185868db54006551813cdbf8db/233a3/assets/images/logo.svg"
                    />
                  </div>
                  <img alt="marc marmel" src={marcMarmelThumbnail} />
                  <h3>Marc Marmel</h3>
                  <p>Luxury cracked-leather goods</p>
                  <Link to="/portfolio/marc-marmel">View Project</Link>
                </div>
              </Col>
              <Col md={4}>
                <div className={s.featuredProject}>
                  <div>
                    <img
                      alt="sun wellness logo"
                      className={s.sunWellnessLogo}
                      src={saraLogo}
                    />
                  </div>
                  <img alt="sun wellness background" src={saraThumbnail} />
                  <h3>SARA</h3>
                  <p>Innovative technology pioneers</p>
                  <Link to="/portfolio/sara">View Project</Link>
                </div>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.whatWeDo}>
          <Grid>
            <Row>
              <Col sm={4}>
                <h2>What We Do</h2>
                <div className={s.icon}>
                  <FontAwesomeIcon icon={faLaptopCode} />
                </div>
              </Col>
              <Col sm={8}>
                <p>
                  We are a web development company that provides custom
                  software, web development, and web design. Whether you want
                  your company on the web, mobile or both, Flux Tech is here to
                  help.
                </p>
                <Link to="/solutions">Learn More</Link>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.whoWeAre}>
          <Grid>
            <Row>
              <Col sm={12}>
                <h2>The Company</h2>
              </Col>
              <Col sm={12}>
                <div className={s.team}>
                  <img
                    alt="Jonathan Emig"
                    className={s.memberPhoto}
                    src="/images/uncompressed/jonathan-poster-v3.jpg"
                  />
                  <p>
                    Founded in 2015 by Jonathan Emig, Flux Tech started as a way
                    to help small businesses navigate the complicated web
                    development landscape.
                  </p>
                  <Link to="/team">Learn About Us</Link>
                </div>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.howToStart}>
          <Grid>
            <Row>
              <Col sm={4}>
                <h2>How To Start</h2>
                <div className={s.icon}>
                  <FontAwesomeIcon icon={faPlayCircle} />
                </div>
              </Col>
              <Col sm={8}>
                <p>
                  We have gone through the process many times and we understand
                  the challenges you will have to face. We would love to help
                  you understand step-by-step the best way to go about building
                  your next application.
                </p>
                <Link to="/how-to-start">Show Me The Steps</Link>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className={s.testimonials}>
          <div
            className={s.testimonialContainer}
            style={{
              width: `${testimonials.length * 100}%`,
              marginLeft: `-${(this.state.testimonialNumber - 1) * 100}%`,
            }}
          >
            {testimonials.map(testimonial => (
              <div key={`key-${uniqueId()}`} className={s.center}>
                <div className={s.testimonial}>
                  {testimonial.image.href ? (
                    <a
                      href={testimonial.image.href}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        alt={testimonial.image.alt}
                        src={testimonial.image.src}
                      />
                    </a>
                  ) : (
                    <img
                      alt={testimonial.image.alt}
                      src={testimonial.image.src}
                    />
                  )}
                  <p>
                    {testimonial.paragraph.content}
                    <br />
                    <br />
                    <span>- {testimonial.paragraph.name}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={s.navigationContainer}>
            <div className={s.navigation}>
              {testimonials.map((testimonial_unused, idx) => (
                <button
                  key={uniqueId()}
                  className={s.dot}
                  onClick={() => this.setState({ testimonialNumber: idx + 1 })}
                  style={
                    this.state.testimonialNumber === idx + 1
                      ? {
                          border: '2px solid var(--link--color)',
                          background: 'white',
                        }
                      : {}
                  }
                  type="button"
                />
              ))}
            </div>
          </div>
        </section>

        {/*
              <section className={s.ourServices}>
          <Grid>
            <Row>
              <Col sm={4}>
                <h2>Testimonials</h2>
                <FontAwesomeIcon icon={faBriefcase} />
              </Col>
              <hr />
              <Col sm={8}>
                <p>
                  We are a software company that helps you make smart business
                  decisions by providing robust, intelligent software. Whether
                  you want you're company on the web, mobile or both, Flux Tech
                  is here to help.
                </p>
                <Link to="/solutions">Learn More</Link>
              </Col>
            </Row>
          </Grid>
        </section>
      */}
      </React.Fragment>
    );
  }
}

export default withStyles(s)(Home);
